import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import EmailForm from '../EmailForm'
import { DictionaryFormContext } from '../../contexts'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color,
  border,
  zIndex
} from '../../styles'

const BackInStockPopUp = ({ backInStockPopUpOpen }) => {
  const dictionaryForm = useContext(DictionaryFormContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(backInStockPopUpOpen)
  }, [backInStockPopUpOpen])

  return (
    <>
      {open && (
        <StyledBackInStockPopUp>
          <Title>{dictionaryForm.titleBackInStockPopUp}</Title>
          <IconWrapper onClick={() => setOpen(false)}>
            <Icon name='hamburger' open />
          </IconWrapper>
          <StyledEmailForm className='email-form-back-in-stock' />
        </StyledBackInStockPopUp>
      )}
    </>
  )
}

const StyledBackInStockPopUp = styled.div`
  height: ${mobileVW(296)};
  width: ${mobileVW(300)};
  background: ${color.yellow};
  border: ${border.medium};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${zIndex.medium};
  padding: ${mobileVW(60)} ${mobileVW(45)};

  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    top: ${desktopVW(350)};
    left: 50%;
    transform: translate(-50%, 0%);
    height: ${desktopVW(296)};
    width: ${desktopVW(300)};
    padding: ${desktopVW(50)};
  }
`

const IconWrapper = styled.div`
  position: absolute;
  top: ${mobileVW(16)};
  right: ${mobileVW(16)};
  width: ${mobileVW(16)};
  height: ${mobileVW(16)};

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVW(20)};
    right: ${desktopVW(20)};
    width: ${desktopVW(20)};
    height: ${desktopVW(20)};
  }
`

const Title = styled.p`
  font-weight: bold;
  text-transform: initial;
  text-align: center;
  font-size: ${mobileVW(30)};
  line-height: ${mobileVW(26)};
  padding-bottom: ${mobileVW(30)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(30)};
    line-height: ${desktopVW(26)};
    padding-bottom: ${desktopVW(30)};
  }
`

const StyledEmailForm = styled(EmailForm)`
  &.email-form-back-in-stock {
    margin-top: ${mobileVW(30)};
    width: 100%;
    left: 50%;
    bottom: ${mobileVW(30)};
    transform: translateX(-50%);
    position: relative;

    @media (min-width: ${desktopBreakpoint}) {
      margin-top: ${desktopVW(30)};
      bottom: ${desktopVW(30)};
    }
  }
`

export default BackInStockPopUp
