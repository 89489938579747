import React, { useState, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import fetch from 'isomorphic-fetch'
import { decode } from 'shopify-gid'
import { DictionaryContext, LayoutContext } from '../contexts'
import SEO from '../components/SEO'
import ProductImages from '../components/product/ProductImages'
import ProductForm from '../components/product/ProductForm'
import Slider from '../components/Slider'
import StickyAddToCart from '../components/product/StickyAddToCart'
import ProductTextImage from '../components/product/ProductTextImage'
import ReviewsChat from '../components/ReviewsChat'
import useShopifyProduct from '../hooks/checkout/useShopifyProduct'
import useSticky from '../hooks/window/useSticky'
import { desktopBreakpoint } from 'styles/index'

const Product = ({
  data: {
    contentfulProductPage: {
      slug,
      seoTitle,
      seoDescription,
      title,
      productImages,
      shopifyProduct,
      notesIngredients,
      description,
      applyOn,
      color,
      colorVariants,
      pairWith,
      completeTheSet,
      relatedProducts,
      textImages,
      preorder,
      shipsBy
    }
  }
}) => {
  const dictionary = useContext(DictionaryContext)
  const { headerMenus, node_locale } = useContext(LayoutContext)
  const { lineItems } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain]
  )
  const quantity = useSelector(({ selections }) => selections.quantity)

  const [price, setPrice] = useState()
  const [variants, setVariants] = useState([])
  const [productId, setProductId] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState()
  const [selectedVariantSize, setSelectedVariantSize] = useState()
  const [sizeFormat, setSizeFormat] = useState()
  const [lineItem, setLineItem] = useState(null)
  const [outOfStock, setOutOfStock] = useState(false)
  const [lowStock, setLowStock] = useState(false)
  const [productReviews, setProductReviews] = useState([])

  const { product } = useShopifyProduct(shopifyProduct.handle)

  const [stickyAddToCartRef, ref] = useSticky(false, true)
  const [refProduct, inViewRefProduct] = useInView({ rootMargin: '-100% 0px 0px 0px' })

  const getProductReviews = async (pI) => {
    const shopifyProductId = await decode(pI).id
    const yotpoAppKey = node_locale === 'it'
      ? process.env.GATSBY_YOTPO_IT_APP_KEY
      : node_locale === 'es'
        ? process.env.GATSBY_YOTPO_ES_APP_KEY
        : process.env.GATSBY_YOTPO_EN_APP_KEY

    const data = await fetch(`https://api.yotpo.com/v1/widget/${yotpoAppKey}/products/${shopifyProductId}/reviews.json?per_page=150`)
      .then(res => res.json())
      .then(parsed => parsed.response.reviews)
      .catch(err => console.error(err))

    return setProductReviews(data)
  }

  useEffect(() => {
    if (product) {
      setPrice(
        product.variants.edges[0].node.presentmentPrices.edges[0].node.price
          .amount
      )
      setVariants(product.variants.edges)
      setProductId(product.id)
      setSelectedVariant(product.variants.edges[0].node.id)
      setSelectedVariantSize(
        product.variants.edges[0].node.selectedOptions[0].value
      )
      setSizeFormat(
        product.variants.edges[0].node.selectedOptions[0].name.includes('ML') ? 'ml' : 'gr'
      )
      setLineItem(
        lineItems.edges.find(
          ({
            node: {
              variant: { id }
            }
          }) => id === product.variants.edges[0].node.id
        )
      )
    }
  }, [lineItems.edges, product])

  useEffect(() => {
    if (variants.find(({ node }) => !node.availableForSale)) {
      setOutOfStock(true)
    } else {
      setOutOfStock(false)
    }

    if (variants.find(({ node }) => node.quantityAvailable <= 10)) {
      setLowStock(true)
    } else {
      setLowStock(false)
    }
  }, [variants])

  useEffect(() => {
    if (!productId || !window) return
    getProductReviews(productId)
  }, [productId])

  return (
    <Wrapper ref={ref}>
      <SEO
        isProduct
        slug={slug}
        title={seoTitle}
        description={seoDescription}
      />
      <Wrapper ref={refProduct}>
        <ProductImages productImages={productImages} review={productReviews[0]} />
        <ProductForm
          productForm={{ title, shopifyProduct, color, colorVariants }}
          productInfo={{
            price,
            variants,
            quantity,
            lineItem,
            productId,
            selectedVariant,
            selectedVariantSize,
            sizeFormat,
            outOfStock,
            lowStock,
            preorder,
            shipsBy
          }}
          productAccordion={{ notesIngredients, description }}
          pairWith={pairWith}
          completeTheSet={completeTheSet}
        />
      </Wrapper>
      {relatedProducts && (
        <Slider
          slider={{
            title: dictionary.relatedProducts,
            filters: false,
            slides: relatedProducts,
            cta: dictionary.viewAllProducts,
            ctaLink: { link: [{ slug: headerMenus[0].link[0].slug }] }
          }}
        />
      )}
      {textImages && (
        <ProductTextImage textImages={textImages} />
      )}
      {/* <ReviewsChat reviews={productReviews} product /> */}
      <StickyAddToCart
        ref={stickyAddToCartRef}
        show={!inViewRefProduct}
        outOfStock={outOfStock}
        stickyAddToCart={{
          title,
          shopifyProduct,
          image: productImages[0].foregroundImage,
          pairWith
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  
  @media (min-width: ${desktopBreakpoint}) {
    .swiper-wrapper {
      transform: none;
    }
  }
`

export const PageQuery = graphql`
  query Product($node_locale: String!, $id: String!) {
    contentfulProductPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      slug
      seoTitle
      seoDescription
      ...productPage
      notesIngredients {
        notesIngredients
      }
      description {
        description
      }
      pairWith {
        ...productPage
      }
      completeTheSet {
        ...completeTheSet
      }
      relatedProducts {
        ...productPage
      }
      textImages {
        ...textImage
      }
    }
  }
`

export default Product
