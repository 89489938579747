import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import { SiteLink } from '../SiteLink'
import RichText from '../RichText'
import Icon from '../Icon'
import useRootMargin from '../../hooks/window/useRootMargin'
import useHeaderColor from '../../hooks/useHeaderColor'
import { mobileVW, desktopVW, color, desktopBreakpoint } from '../../styles'

const ProductTextImageElement = ({ index, title, styledTitle, body, image, cta, link, imageRight, firstTextImage }) => {
  const rootMargin = useRootMargin()
  const [refProdTextImageEl, inViewProdTextImageEl] = useInView({ rootMargin })

  useHeaderColor(
    inViewProdTextImageEl,
    {
      left: imageRight ? color.black : color.yellow,
      center: color.black,
      right: color.balck
    },
    {
      left: color.black,
      center: color.yellow,
      right: color.black
    }
  )

  const titleStyling = (t) => {
    const splitWords = t.split(' ')
    return splitWords.map((word, i) => <div key={i}>{word}</div>)
  }

  return (
    <StyledTextImage ref={!imageRight ? refProdTextImageEl : null}>
      <ImageWrapper
        imageRight={imageRight}
        firstTextImage={firstTextImage}
      >
        <Image fluid={image.fluid} alt={image.title} />
      </ImageWrapper>
      <TextWrapper
        styledTitle={styledTitle}
        imageRight={imageRight}
        firstTextImage={firstTextImage}
        longTitle={title.length > 20}
      >
        <Title longTitle={title.length > 20}>
          {styledTitle ? titleStyling(title) : title}
        </Title>
        <RichText json={JSON.parse(body.body)} />
        {cta && link && (
          <Cta slug={link.link[0].slug}>
            {cta}
            <Icon name='cta-arrow' />
          </Cta>
        )}
      </TextWrapper>
    </StyledTextImage>
  )
}

const ProductTextImage = ({ textImages }) => (
  <>
    {textImages.map(
      ({ title, styledTitle, body, image, cta, link }, index) => (
        <ProductTextImageElement
          key={index}
          index={index}
          title={title}
          styledTitle={styledTitle}
          body={body}
          image={image}
          cta={cta}
          link={link}
          imageRight={index % 2 === 0}
          firstTextImage={index === 0}
        />
      )
    )}
  </>
)

const StyledTextImage = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
  }
`

const TextWrapper = styled.div`
  order: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: ${({ firstTextImage }) => (firstTextImage ? mobileVW(40) : '0')};
  padding: ${mobileVW(111)} ${mobileVW(60)};
  background: ${color.yellow};
  color: ${color.black};

  p {
    text-transform: initial;
    font-size: ${mobileVW(16)};
    line-height: ${mobileVW(26)};
    padding: 0 ${mobileVW(36)} ${mobileVW(16)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    order: ${({ imageRight }) => (imageRight ? '1' : '2')};
    height: ${desktopVW(900)};
    width: 50%;
    margin-top: ${({ firstTextImage }) =>
      firstTextImage ? desktopVW(90) : '0'};
    padding: ${desktopVW(180)} ${desktopVW(70)};

    p {
      font-size: ${desktopVW(16)};
      line-height: ${desktopVW(26)};
      padding: 0 ${desktopVW(70)} ${desktopVW(20)};
    }
  }
`

const Title = styled.h3`
  position: relative;
  font-size: ${mobileVW(42)};
  line-height: ${mobileVW(36)};
  padding: 0 ${mobileVW(36)} ${mobileVW(16)};

  div:nth-child(even) {
    display: inline-block;
    padding: ${mobileVW(3)};
    background: ${color.black};
    color: ${color.yellow};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${({ longTitle }) =>
      longTitle ? desktopVW(60) : desktopVW(80)};
    line-height: ${({ longTitle }) =>
      longTitle ? desktopVW(51) : desktopVW(68)};
    padding: ${({ longTitle }) =>
      longTitle
        ? `0 ${desktopVW(20)} ${desktopVW(20)}`
        : `0 ${desktopVW(70)} ${desktopVW(20)}`};
  }
`

const ImageWrapper = styled.div`
  order: 2;
  height: ${mobileVW(453)};
  width: 100%;
  margin-top: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media (min-width: ${desktopBreakpoint}) {
    order: ${({ imageRight }) => (imageRight ? '2' : '1')};
    height: ${desktopVW(900)};
    margin-top: ${({ firstTextImage }) =>
      firstTextImage ? desktopVW(90) : '0'};
    width: 50%;
  }
`

const Cta = styled(SiteLink)`
  display: inline-block;
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(14)};
  font-weight: bold;
  color: ${color.black};

  svg {
    position: relative;
    width: ${mobileVW(22)};
    height: ${mobileVW(22)};
    padding-left: ${mobileVW(6)};
    top: ${mobileVW(5)};

    circle {
      fill: ${color.black};
    }

    path {
      fill: ${color.white};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(16)};
    line-height: ${desktopVW(20)};

    svg {
      width: ${desktopVW(22)};
      height: ${desktopVW(22)};
      padding-left: ${desktopVW(6)};
      top: ${desktopVW(6)};
    }
  }
`

export default ProductTextImage
