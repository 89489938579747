import React, { useContext } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { SiteLink } from '../SiteLink'
import RichText from '../RichText'
import Price from '../Price'
import useReplaceLineItem from '../../hooks/checkout/useReplaceLineItem'
import useUpdateCheckoutAttributes from '../../hooks/checkout/useUpdateCheckoutAttributes'
import { useGtmProductDetailView, gtmAddProductToCart } from '../../hooks/useGtm'
import { setCartOpen, setQuantity, addUpsells } from '../../actions'
import { DictionaryContext, StoreContext } from '../../contexts'
import { mobileVW, desktopVW, desktopBreakpoint, color } from '../../styles'

const CompleteTheSet = ({
  completeTheSet: {
    slug,
    productImages,
    title,
    description,
    shopifyProduct
  }, className
}) => {
  const dictionary = useContext(DictionaryContext)
  const { currency } = useContext(StoreContext)
  const [replaceLineItem, { loading }] = useReplaceLineItem()

  return (
    <StyledCompleteTheSet className={className}>
      <Title>{dictionary.completeTheSet}</Title>
      <Wrapper>
        <ImageWrapper slug={slug}>
          <Image
            fluid={productImages[0].foregroundImage
              ? productImages[0].foregroundImage.fluid
              : null}
            alt={productImages[0].foregroundImage
              ? productImages[0].foregroundImage.title
              : null}
          />
        </ImageWrapper>
        <InfoWrapper slug={slug}>
          <ComboTitle>{title}</ComboTitle>
          <Description>
            <RichText json={JSON.parse(description.description)} />
          </Description>

        </InfoWrapper>
        <StyledButton
          aria-label='add to cart'
          // onClick={() => addProductToCart()}
        >
          {!loading ? (
            <>
              <Price shopifyProduct={shopifyProduct} />
              {currency} - {dictionary.addToCart}
            </>
          ) : dictionary.addToCartLoading}

        </StyledButton>
      </Wrapper>
    </StyledCompleteTheSet>
  )
}

const StyledCompleteTheSet = styled.div`
  order: 5;
  position: relative;
  width: 100%;
  padding-top: ${mobileVW(10)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${desktopVW(50)};
  }
`

const Title = styled.h3`
  position: relative;
  font-size: ${mobileVW(20)};
  line-height: ${mobileVW(17)};
  padding: ${mobileVW(16)} ${mobileVW(16)} ${mobileVW(32)};
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(20)};
    line-height: ${desktopVW(17)};
    padding: 0 0 ${desktopVW(25)} 0;
    text-align: left;
  }
`

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(246)};
    margin: 0;
  }
`

const ImageWrapper = styled(SiteLink)`
  width: 40%;
  height: ${mobileVW(162)};
  background: ${color.grey};
  margin-right: ${mobileVW(16)};
  overflow: hidden;
  position: relative;

  .gatsby-image-wrapper {
    margin-top: ${mobileVW(16)};
    height: 80%;

    img {
      object-fit: contain !important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(162)};
    margin-right: ${desktopVW(20)};

    .gatsby-image-wrapper {
      margin-top: ${desktopVW(16)};
    }
  }
`

const InfoWrapper = styled(SiteLink)`
  width: calc(60% - ${mobileVW(16)});
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(19)};
  
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(19)};
    width: calc(60% - ${desktopVW(20)});
  }
`

const ComboTitle = styled.p`
  margin-bottom: ${mobileVW(12)};
  text-transform: initial;

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVW(12)};
  }
`

const Description = styled.div`
  p, ul {
    display: none;
    font-weight: normal;
    text-transform: initial;

    &:first-child {
      display: block;
    }
  }
`

const StyledButton = styled.button`
  background-color: ${color.black};
  color: ${color.white};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  width: 100%;
  height: ${mobileVW(60)};
  margin-top: ${mobileVW(20)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(10)};
    padding: ${desktopVW(10)};
    height: ${desktopVW(61)};
    margin-top: ${desktopVW(20)};
  }
`

export default CompleteTheSet
