import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { DictionaryContext } from '../../contexts'
import RichText from '../RichText'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  underline
} from '../../styles'

const ProductAccordion = ({
  productAccordion: { notesIngredients, description }
}) => {
  const dictionary = useContext(DictionaryContext)
  const [open, setOpen] = useState('description')

  const handleClick = (accordion) =>
    accordion === open ? setOpen(null) : setOpen(accordion)

  return (
    <StyledProductAccordion>
      <AccordionWrapper>
        <AccordionButton
          className={open === 'description' ? 'is-selected' : null}
          onClick={() => handleClick('description')}
        >
          {dictionary.description}
        </AccordionButton>

        <AccordionButton
          className={open === 'notes-ingredients' ? 'is-selected' : null}
          onClick={() => handleClick('notes-ingredients')}
        >
          {dictionary.notesIngredients}
        </AccordionButton>

        <AccordionButton
          className={open === 'shipping' ? 'is-selected' : null}
          onClick={() => handleClick('shipping')}
        >
          {dictionary.shippingReturns}
        </AccordionButton>
      </AccordionWrapper>
      <Content show={open === 'description'}>
        <RichText
          json={JSON.parse(description.description)}
        />
      </Content>
      <Content show={open === 'notes-ingredients'}>
        <RichText
          json={JSON.parse(notesIngredients.notesIngredients)}
        />
      </Content>
      <Content show={open === 'shipping'}>
        <RichText
          json={JSON.parse(dictionary.shippingReturnsBody.shippingReturnsBody)}
        />
      </Content>
    </StyledProductAccordion>
  )
}

const StyledProductAccordion = styled.div`
  order: 3;

  @media (min-width: ${desktopBreakpoint}) {
    order: 2;
  }
`

const AccordionWrapper = styled.ul`
  padding: ${mobileVW(30)} 0 0;
  height: 100%;
  display: flex;
  list-style-type: none;
  justify-content: space-between;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(15)} 0 0;
    width: ${desktopVW(320)};
  }
`

const AccordionButton = styled.li`
  font-size: ${mobileVW(10)};
  line-height: ${mobileVW(10)};
  display: inline-block;
  cursor: pointer;
  padding: ${mobileVW(2)} 0;

  &.is-selected {
    ${underline.static}
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(10)};
    line-height: ${desktopVW(10)};
    padding: 0 ${desktopVW(5)} 0 0;

    ${underline.hover}

    &.is-selected {
      &:after {
        transform: scale(1);
      }
    }
  }
`

const Content = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(19)};
  padding: ${mobileVW(25)} ${mobileVW(32)} 0;
  text-align: center;

  p {
    text-transform: initial;
    padding-bottom: ${mobileVW(22)};

    b {
      text-transform: initial;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(19)};
    padding: ${desktopVW(20)} 0;
    width: ${desktopVW(320)};
    flex-direction: column;
    text-align: left;

    p {
      padding-bottom: ${desktopVW(30)};

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
`

export default ProductAccordion
