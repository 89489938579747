import React, { useContext } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { SiteLink } from '../SiteLink'
import Price from '../Price'
import { StoreContext } from '../../contexts'
import { mobileVW, desktopVW, color, desktopBreakpoint } from '../../styles'

const PairWith = ({ pairWith: { title, items } }) => {
  const { currency } = useContext(StoreContext)
  return (
    <StyledPairWith>
      <Title>{title}</Title>
      <Wrapper>
        {items.map(
          (
            { title: productTitle, slug, productImages, shopifyProduct },
            index
          ) => {
            if (index === 2) { return null } else {
              return (
                <PairWithItem key={index} slug={slug}>
                  <ImageWrapper>
                    <Image
                      fluid={productImages[0].foregroundImage
                        ? productImages[0].foregroundImage.fluid
                        : null}
                      alt={productImages[0].foregroundImage
                        ? productImages[0].foregroundImage.title
                        : null}
                    />
                  </ImageWrapper>
                  <ProductTitle>{productTitle}</ProductTitle>
                  <ProductPrice>
                    <Price shopifyProduct={shopifyProduct} noComparison />
                    <span>{currency}</span>
                  </ProductPrice>
                </PairWithItem>
              )
            }
          }
        )}
      </Wrapper>
    </StyledPairWith>
  )
}

const StyledPairWith = styled.div`
  order: 4;
  position: relative;
  width: 100%;
  padding-top: ${mobileVW(10)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: ${desktopVW(40)};
    height: ${desktopVW(312)};
  }
`

const Title = styled.h3`
  position: relative;
  font-size: ${mobileVW(20)};
  line-height: ${mobileVW(17)};
  padding: ${mobileVW(16)} ${mobileVW(16)} ${mobileVW(32)};
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(20)};
    line-height: ${desktopVW(17)};
    padding: 0 0 ${desktopVW(25)} 0;
    text-align: left;
  }
`

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin: 0 -${mobileVW(16)};

  a:last-child {
    margin-right: 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(246)};
    margin: 0;
  }
`

const PairWithItem = styled(SiteLink)`
  height: ${mobileVW(224)};
  width: ${mobileVW(163)};
  background: ${color.grey};
  margin-right: ${mobileVW(16)};
  overflow: hidden;
  position: relative;

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(246)};
    width: ${desktopVW(179)};
    margin-right: ${desktopVW(20)};
  }
`

const ImageWrapper = styled.div`
  height: ${mobileVW(116)};
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .gatsby-image-wrapper {
    height: 100%;

    img {
      object-fit: contain !important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(128)};
  }
`

const ProductTitle = styled.p`
  position: absolute;
  width: ${mobileVW(85)};
  bottom: ${mobileVW(16)};
  left: ${mobileVW(16)};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(12)};

  @media (min-width: ${desktopBreakpoint}) {
    bottom: ${desktopVW(20)};
    left: ${desktopVW(20)};
    width: ${desktopVW(85)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(12)};
  }
`

const ProductPrice = styled.div`
  position: absolute;
  bottom: ${mobileVW(16)};
  right: ${mobileVW(16)};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(21)};
  background: ${color.yellow};
  transform: rotate(-3.17deg);
  padding: 0 ${mobileVW(4)};

  span {
    position: relative;
    top: -${mobileVW(4)};
    padding-left: ${mobileVW(2)};
    font-size: ${mobileVW(6)};
    line-height: ${mobileVW(10)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    bottom: ${desktopVW(20)};
    right: ${desktopVW(20)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(21)};
    padding: 0 ${desktopVW(4)};
    background: ${color.black};
    color: ${color.white};

    span {
      position: relative;
      top: -${desktopVW(6)};
      padding-left: ${desktopVW(2)};
      font-size: ${desktopVW(6)};
      line-height: ${desktopVW(10)};
    }
  }
`

export default PairWith
